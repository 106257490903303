/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';
import '../scss/bootstrap.scss'; // mixins reimportés dans assets/scss/variables.scss
import '../scss/main.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery';
	// ou
// const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it

// the bootstrap module doesn't export/return anything
require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

$(document).ready(function() {
	// $('[data-toggle="popover"]').popover();
	$('.popover').popover();
	$('.dismissible-popover').popover({
		trigger: 'focus'
	})
	$('.modal.auto').modal();
	$('.carousel').carousel({
		interval: 4000
	})
});

// custom js
import './ici-grenoble.js';
// import ici-grenoble-js from './ici-grenoble'; // ES6 cf. https://symfony.com/doc/current/frontend/encore/simple-example.html#the-import-and-export-statements
// import './pages/home.js'; // importé dans les templates


// import depuis bundles
// -> pugxautocompleter disabled
// import '../../public/bundles/pugxautocompleter/js/autocompleter-select2.js';
// import '../../public/bundles/pugxautocompleter/js/autocompleter-jqueryui.js';

// import font-awesome
// après yarn add @fortawesome/fontawesome-free --dev
// cf. https://stackoverflow.com/questions/51571170/how-to-add-fontawesome-5-to-symfony-4-using-webpack-encore 
// cf. https://fontawesome.com/how-to-use/on-the-web/setup/using-package-managers#next
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

// import jquery-ui
// après yarn add jquery-ui --dev
// cf. https://stackoverflow.com/questions/33998262/jquery-ui-and-webpack-how-to-manage-it-into-module
// cf. https://jqueryui.com/upgrade-guide/1.12/#official-package-on-npm
require("jquery-ui/themes/base/all.css");
// require("jquery-ui/themes/base/autocomplete.css");
var autocomplete = require( "jquery-ui/ui/widgets/autocomplete" );





// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
