// require jQuery normally, cf. https://symfony.com/doc/current/frontend/encore/legacy-applications.html
const $ = require('jquery');

$(document).ready(function() {
	// console.log ("js loaded from assets/js/ici-grenoble.js");
	
	// menu superieur 
	var mainNav = $('nav.main-menu');
	var mainNavPos = $(mainNav).offset().top;
    var documentHeight = $(document).height();
    console.log('documentHeight: ' + documentHeight);
	$(window).scroll(function(){
		var scrollPos = $(document).scrollTop();
        // console.log('mainNavPos: ' + mainNavPos);
		// console.log('scrollPos: ' + scrollPos);
		if (scrollPos > mainNavPos && $(window).width() > 991 && documentHeight > 1180) {
			if (! $(mainNav).hasClass("fixed-top shadow-sm")) {				
				// $(mainNav).fadeOut();
				$(mainNav).addClass("fixed-top shadow-sm");
				// $(mainNav).fadeIn("slow");
			}
		} else {
			$(mainNav).removeClass("fixed-top shadow-sm");			
		}
	});
    $('#searchForm').on('shown.bs.collapse', function () {
        // focus input on collapse
        $("#search").focus()
    })
    $('#searchForm').on('hidden.bs.collapse', function () {
        // focus input on collapse
        $("#search").blur()
    })
    $('#searchForm a.submit-btn').on('click', function () {
        let term = $("#searchForm input").val();
        searchRoute = searchRoute.replace('term', term); // searchRoute est chargé dans base.html.twig
        window.location.href = searchRoute;
    })
    $(document).on('keypress',function(e) {
        if(e.which == 13) { // pressed enter
            if (e.target == $('#inlineFormInputSearch')[0]) {
                let term = $("#searchForm input").val();
                searchRoute = searchRoute.replace('term', term); // searchRoute est chargé dans base.html.twig
                window.location.href = searchRoute;
            } else if (e.target == $('#recherche_term')[0]) {
                let term = $("#recherche_term").val();
                searchRoute = searchRoute.replace('term', term); // searchRoute est chargé dans base.html.twig
                window.location.href = searchRoute;
            }
        }
    });


	// tag autocompletion
	$( "#recherche_term" ).autocomplete({
		source: function( request, response ) {
            $.ajax( {
                // url: Routing.generate('autocomp_tag'), // requires https://symfony.com/doc/2.x/bundles/FOSJsRoutingBundle/installation.html
				url: "/tag/autocomp_tag",
				dataType: "json",
                data: {
                    term: request.term
                },
                success: function( data ) {
					var resultCount = data['items'].length;
					console.log("nb réponses tag autocompletion : " + resultCount );
                    response( data['items'] );
                }
            } );
		},
		minLength: 2,
		select: function( event, ui ) {
			console.log( "Selected value/label: " + ui.item.value + "/" + ui.item.label );
		}
	});

	// changement dynamique agenda step by step
	$('.agenda a.change-agenda').click(function(e) {
		e.preventDefault();
        console.log('agenda stepNb', stepNb);
        console.log('agenda baseDateTime', baseDateStr);
		if( $(this).hasClass('previous') ) {
			offset = offset - stepNb;
		} else if ( $(this).hasClass('next') ) {
			offset = offset + stepNb;
		} 
        console.log('agenda offset', offset);
		$.ajax( {
			// url: Routing.generate('agendaStepByStep', {'stepNb': stepNb, 'offset': offset, 'baseDateStr': baseDateStr}), // requires https://symfony.com/doc/2.x/bundles/FOSJsRoutingBundle/installation.html
			url: "/agenda/step-by-step/" + stepNb + "/" + offset + "/" + baseDateStr,
			success: function( data ) {
				// console.log("data", data );
				$('.agenda .agenda-dyn-content').html(data);
			}
		} );
	});

	// // changement dynamique agenda fromTo
	// $('.agenda a.change-agenda').click(function(e) {
	// 	e.preventDefault();
	// 	if( $(this).hasClass('previous') ) {
	// 		console.log('formattedPreviousFromDate', formattedPreviousFromDate);
	// 		var fromDate = formattedPreviousFromDate;
	// 	} else if ( $(this).hasClass('next') ) {
    //         console.log('formattedNextFromDate', formattedNextFromDate);
	// 		var fromDate = formattedNextFromDate;
	// 	} 
	// 	$.ajax( {
	// 		// url: Routing.generate('agendaFromTo', {'agendaFrom' : fromDate}), // requires https://symfony.com/doc/2.x/bundles/FOSJsRoutingBundle/installation.html
	// 		url: "/agenda-from-to/" + fromDate,
	// 		success: function( data ) {
	// 			// console.log("data", data );
	// 			$('.agenda .agenda-dyn-content').html(data);
	// 		}
	// 	} );
	// });
});